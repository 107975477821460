import React from 'react';
import "./assets/css/App.css"
import { TopBar } from "./components/TopBar";
import PlatformProducts from './components/PlatformProducts';

const App = () => {

  return (
    <>
      <TopBar/>
      <PlatformProducts/>
    </>
  )
}

export default App