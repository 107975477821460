import React from "react";
import MasterWorldMobile from '../assets/img/masterWorldMobile.png'
import analytics from "../assets/img/Analytics.svg"
import assigner from "../assets/img/Assigner.svg"
import crm from "../assets/img/Crm.svg"
import geopanel from "../assets/img/GeoPanel.svg"
import '../assets/css/PlatformProducts.css'

const PlatformProducts = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const slug = searchParams.get('client')
  
  return (
    <div className='where-sales-happen'>
        <img src={MasterWorldMobile} alt='Master Dealer World' className='masterdealer-world'/>
        <br/><br/>
        <div className='button-position'>
          <a href="https://analytics.masterdealer.co" id='analytics'>
            <button value="ANALYTICS"><img alt='analytics' src={analytics} className='button-image-analytics'/>Analytics</button>
          </a>
          <a href={`https://assigner.${slug}.masterdealer.co`} id='assigner'>
            <button value="ASSIGNER"><img alt='assigner' src={assigner} className='button-image-assigner'/>Assigner</button>
          </a>
          <a href={`https://crm.${slug}.masterdealer.co`} id='crm'>
            <button value="CRM"><img alt='crm' src={crm} className='button-image-crm'/>CRM</button>
          </a>
          { searchParams.get('geo') === 'true' ? (
            <a href={`https://geo-panel.${slug}.masterdealer.co`} id='geo'>
              <button value="GEO PANEL"><img alt='geo' src={geopanel} className='button-image-geo'/>Geo Panel</button>
            </a>
          ):(
            <span></span>
          )
          }
      </div>
    </div>
  )
}

export default PlatformProducts