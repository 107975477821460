import React from "react";
import logo from "../assets/img/MasterDealerLogo.png";
import "../assets/css/topBar.css";

export const TopBar = () => {

  return (
    <div className="top-bar">
      <div className="img-container-top-bar">
          <img src={logo} alt="logo" />
      </div>
    </div>
  );
};

export default TopBar